
/**
 * @name: 销售管理-作废单审核
 * @author:
 * @date: 2023-08-04 17:53
 * @description： 销售管理-作废单审核
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {
  cancelOrderAuditApi,
  cancelOrderDetailApi,
  cancelOrderExportApi,
  cancelOrderQueryApi
} from "@/apis/sale/voidOrder";
import {deepCopy, exportFile} from "@/utils/common";
import {ICancelOrder, ICancelOrderParams} from "@/apis/sale/voidOrder/types";
import Big from "big.js";

@Component({})
export default class saleVoidOrder extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ICancelOrderParams = {
    page: 1,
    limit: 10
  }
  pageData: any = {
    orderPrice: '',
    payPrice: ''
  }
  // 表单参数
  modelForm: Partial<ICancelOrder> = {}

  // crud配置信息
  crudOption: ICrudOption = {
    searchBox: true,
    menu: true,
    labelWidth: '160px',
    column: [
      {
        label: '交易流水ID',
        prop: 'saleOrderIdKeywords',
        align: 'left',
        search: true,
        hide:true,
        addHide:true,
        editHide:true,
        viewHide:true,
        width: 180
      },
      {
        label: '交易流水ID',
        prop: 'saleOrderId',
        align: 'left',
        width: 180
      },
      {
        label: '销售订单编号',
        prop: 'saleOrderSn',
        align: 'left',
        width: 180
      },

      {
        label: "档口名称",
        prop: "gateIdList",
        align: "center",
        search: true,
        searchMultiple: true,
        dicUrl: "/api/admin/gate/getGateList",
        dicFormatter: (res: any) => ({list: res.data, label: 'gateName', value: 'id'}),
        type: 'select',
        hide: true,
        viewHide: true
      },
      {
        label: "档口名称",
        prop: "gateName",
        align: "center",
        span: 12
      },
      {
        label: "订单金额",
        prop: "orderPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "支付金额",
        prop: "payPrice",
        align: "center",
        span: 12
      },
      {
        label: "抹零金额",
        prop: "wipeZeroPrice",
        align: "center",
        span: 12,
        viewHide: true
      },
      {
        label: "交易方式",
        prop: "payWayList",
        align: 'center',
        search: true,
        hide:true,
        viewHide:true,
        searchMultiple: true,
        type: "select",  //1=现金 2=微信 3=支付宝 4=混合支付 5=财务结算
        dicData: [
          {label: '现金', value: 1},
          {label: '微信', value: 2},
          {label: '支付宝', value: 3},
          {label: '混合支付', value: 4},
          {label: '财务结算', value: 5},
          {
            label: "抖音",
            value: 6
          },
        ],
        span: 12,
      },
      {
        label: "交易方式",
        prop: "payWay",
        align: 'center',
        type: "select",  //1=现金 2=微信 3=支付宝 4=混合支付 5=财务结算
        dicData: [
          {label: '现金', value: 1},
          {label: '微信', value: 2},
          {label: '支付宝', value: 3},
          {label: '混合支付', value: 4},
          {label: '财务结算', value: 5},
        ],
        span: 12,
      },

      {
        label: "申请人",
        prop: "cancelByName",
        align: "center",
        //@ts-ignore
        default: '--',
        viewHide: true
      },

      {
        label: "审核状态",
        prop: "cancelStatus",
        align: 'center',
        search: true,
        type: "select", //作废单状态:1=待审核 2=已同意 3=已拒绝
        dicData: [
          {label: '待审核', value: 1},
          {label: '已同意', value: 2},
          {label: '已拒绝', value: 3},
        ],
        span: 12,
      },
      {
        label: "审核人",
        prop: "cancelExamineByName",
        align: "center",
        //@ts-ignore
        default: '--',
        viewHide: true
      },

      {
        label: "交易时间",
        prop: "payTime",
        align: "center",
        type: "daterange",
        width: 180,
        //@ts-ignore
        default: '--',
        // @ts-ignore
        defaultTime: ["06:00:00", "05:59:59"],
        search: true,
        span: 12,
        viewHide: true
      },
      {
        label: "支付明细",
        prop: "orderPayStr",
        align: "center",
        hide: true,
      },
      {
        label: "挂账客户",
        prop: "hangingCustomer",
        align: "center",
        hide: true,
      },
    ]
  }

  //计算 明细 -商品金额合计
  get amountTotal() {
    let sum: number = 0;
    if (this.modelForm.saleOrderDtlVoList && this.modelForm.saleOrderDtlVoList.length > 0) {
      for (let i = 0; i < this.modelForm.saleOrderDtlVoList.length; i++) {
        // sum = new Big(this.modelForm.saleOrderDtlVoList[i]["price"] || 0).times(this.modelForm.saleOrderDtlVoList[i]["qty"] || 0).plus(sum).toNumber();
        sum =new Big(sum).plus(this.modelForm.saleOrderDtlVoList[i]["productPrice"] || 0).toNumber()
      }
    }
    return sum.toFixed(2)
  }

  /**
   * 详情
   * @param row
   */
  openDetail(row: ICancelOrder, index: number) {
    cancelOrderDetailApi(row.saleOrderId as string).then(e => {
      if (e) {
        //支付明细
        // @ts-ignore
        e.orderPayStr = '--';
        if (e.orderPayVos) {
          // @ts-ignore
          e.orderPayStr = '';
          e.orderPayVos.forEach(item => {
            // 类型:1=微信 2=支付宝 3=现金
            // @ts-ignore
            e.orderPayStr += `${item.types == 1 ? '微信' : item.types == 2 ? '支付宝' : '现金'} ${item.payPrice}    `
          })
        }

        // @ts-ignore
        e.hangingCustomer ='--';
        if(e.hangingCustomerName&&e.hangingCustomerPhone){
          // @ts-ignore
          e.hangingCustomer = `${e.hangingCustomerName } ${e.hangingCustomerPhone}`
        }
        // @ts-ignore
        this.$refs.crudRef.rowView(e, index)
      }
    })
  }
  /**
   * 审核
   * @param row
   */
  handleAudit(row: ICancelOrder, index: number){
    this.$confirm('您确定要通过该申请吗?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '拒绝',
      type: 'warning',
      distinguishCancelAndClose: true,
    }).then((res) => {
      //	作废单状态:1=待审核 2=已同意 3=已拒绝
      this.orderAudit({saleOrderId:row.saleOrderId,cancelStatus:2});
    }).catch((action) => {
      if(action == 'cancel'){
        //	作废单状态:1=待审核 2=已同意 3=已拒绝
        this.orderAudit({saleOrderId:row.saleOrderId,cancelStatus:3});
      }
    });
  }

  /**
   * 审核提交
   * @param row
   */
  orderAudit(data:any){
    cancelOrderAuditApi(data).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
      }
    })
  }
  /**
   * 导出excel
   */
  exportExcel() {
    const form: ICancelOrderParams = deepCopy(this.queryParam);
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      form.payTimeStart = this.queryParam.payTime[0]
      form.payTimeEnd = this.queryParam.payTime[1]
    } else {
      form.payTimeStart = ""
      form.payTimeEnd = ""
    }
    delete form.payTime
    cancelOrderExportApi(form).then(e => {
      exportFile(e, '作废单.xlsx')
    })
  }

  getList() {
    const form: ICancelOrderParams = deepCopy(this.queryParam);
    if (this.queryParam.payTime && this.queryParam.payTime.length) {
      form.payTimeStart = this.queryParam.payTime[0]
      form.payTimeEnd = this.queryParam.payTime[1]
    } else {
      form.payTimeStart = ""
      form.payTimeEnd = ""
    }
    delete form.payTime
    form.gateIdList=form.gateIdList?.toString();
    form.payWayList=form.payWayList?.toString();
    cancelOrderQueryApi(form).then(e => {
      this.pageData.orderPrice = e.orderPriceTotal || 0;
      this.pageData.payPrice = e.payPriceTotal || 0;
      let {cancelOrderVoPage} = e;
      //@ts-ignore
      this.tableData = cancelOrderVoPage.records;
      this.tableTotal = cancelOrderVoPage.total
    })
  }

  reset() {
    this.getList()
  }

  created() {
    this.reset()
  }
}
