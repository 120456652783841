/**
 * @name: 销售管理-作废单审核接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-作废单审核接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ICancelOrder, ICancelOrderAudit, ICancelOrderParams} from "@/apis/sale/voidOrder/types";

/**
 *  查询作废单列表
 * @param params 查询参数
 * @returns
 */
export const cancelOrderQueryApi = (params: ICancelOrderParams) => get("/admin/cancel/order/queryCancelOrderByPage", params)

/**
 * 查询作废单导出
 * @param params 查询参数
 * @returns
 */
export const cancelOrderExportApi = (params: ICancelOrderParams) => get("/admin/cancel/order/cancelOrderExport", params,"blob")
/**
 * 获取作废单详情
 * @param saleOrderId
 * @returns
 */
export const cancelOrderDetailApi = (saleOrderId: string) => get<ICancelOrder>("/admin/cancel/order/queryCancelOrderDetail",{saleOrderId})

/**
 * 审核作废单
 * @param data
 * @returns
 */
export const  cancelOrderAuditApi = (data: any) => postJ("/admin/cancel/order/audit", data)
